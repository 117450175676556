(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/app-promo/views/app-promo.js') >= 0) return;  svs.modules.push('/components/components/app-promo/views/app-promo.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.app_promo=_cmps.app_promo||{};
_cmps.app_promo.templates=_cmps.app_promo.templates||{};
svs.components.app_promo.templates.app_promo = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " app-promo-bottom-border";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"class":"app-promo-vinnare","brand":"vinnare"},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":63}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"class":"app-promo-vinnare","square":true,"brand":(depth0 != null ? lookupProperty(depth0,"brand") : depth0)},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":71}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"app-promo"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bottomBorder") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":21},"end":{"line":1,"column":72}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"app-promo-brand\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"brand") : depth0),"===","vinnare",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":16}}})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"app-promo-text\">\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":17}}}) : helper))) != null ? stack1 : "")
    + "\n  </div>\n  <button class=\"js-app-promo-close app-promo-close icon-button\">\n    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"color":"fc-grey-600","size":100,"class":"app-promo-icon","icon":"close"},"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":13,"column":82}}}))
    + "\n  </button>\n</div>";
},"useData":true});
Handlebars.partials['components-components-app_promo-app_promo'] = svs.components.app_promo.templates.app_promo;
})(svs, Handlebars);


 })(window);