(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/app-promo/assets/javascripts/app-promo.js') >= 0) return;  svs.modules.push('/components/components/app-promo/assets/javascripts/app-promo.js');

'use strict';

var svs = svs || {};
var isNativeApp = svs.core && svs.core.config && svs.core.config.data && svs.core.config.data.svsconfig && svs.core.config.data.svsconfig.isNativeApp;

svs.components = svs.components || {};

if (isNativeApp) {
  svs.components.AppPromo = function() {
    return {
      close: function() {},
      animateEnd: function() {},
      checkStorage: function() {},
      show: function() {}
    };
  };
} else {
  var logger = svs.core.log.getLogger('component:app-promo');
  svs.components.AppPromo = function(_options) {
    var options = $.extend(
      {},
      {
        el: $('.main-content'),
        brand: 'vinnare',
        message:
            'Kom nära dina sport- och spelupplevelser. <a href="https://www.svenskaspel.se/app/android" target="_blank">Ladda ner Oddsets app för Android här.</a>',
        bottomBorder: true,
        storageDomain: 'appAnnouncement',
        storageKey: null,
        enabled:
            svs.core.detect.formfactor.mobile() && svs.core.detect.os.android(),
        storageExpires: svs.components.Storage.constants.EXPIRES_1YEAR
      },
      _options
    );

    if (!options.storageKey) {
      throw new Error('App Promo: Storage Key not Set');
    }

    var $promoEl = $(svs.components.app_promo.templates.app_promo(options));
    var $closeBtn;

    return {
      close: function() {
        this.setStorage();
        $promoEl.slideUp(200);
      },
      animateEnd: function() {
        $promoEl.remove();
      },
      setStorage: function() {
        var data = {};
        data[options.storageKey] = 1;
        svs.components.Storage.browser.set(
          options.storageDomain,
          'player_notifications',
          data,
          options.storageExpires,
          function() {
          }
        );
      },
      checkStorage: function(callback) {
        svs.components.Storage.browser.get(
          options.storageDomain,
          'player_notifications',
          function(data, err) {
            data = data || {};
            if (err) {
              logger.error('Can not read from local storage:', err);
            } else if (!data[options.storageKey]) {
              callback();
            }
          }
        );
      },
      show: function() {
        var self = this;

        if (options.enabled) {
          this.checkStorage(function() {
            options.el.prepend($promoEl);
            $closeBtn = $promoEl.find('.js-app-promo-close');

            $closeBtn.one('click', function() {
              self.close();
            });

            $promoEl.click(function(event) {
              if ($(event.target).is('a')) {
                self.close();
              } else {
                event.preventDefault();
              }
            });
          });
        }
      }
    };
  };
}


 })(window);